import React from 'react'
import theme from '@ifca-root/react-component/src/assets/theme'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Grid, List, ListItem, useMediaQuery } from '@material-ui/core'
import { formatDateAndTime } from 'helpers/StringNumberFunction/FormatDate'
import QRCode from 'react-qr-code'

export const EInvoiceDialogStatus = (props: any) => {
  const { data, openDialog, setOpenDialog, date, status } = props
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  let einvDate = formatDateAndTime(date)

  return (
    <CommonDialog
      fullWidth={true}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      sections={{
        header: {
          title: 'e-Invoice Information',
          infoLine: data?.DocNo,
        },
        body: () => (
          <>
            <List
              className="item-list content-wrap full text-noflow"
              style={{ paddingTop: '0', paddingBottom: '0' }}
            >
              <ListItem disableGutters>
                <Grid spacing={1} container className="table-content">
                  <Grid
                    item
                    xs={4}
                    style={{ placeSelf: 'start', marginTop: '8px' }}
                  >
                    <div
                      className="p-l-3 desc text-noflow"
                      style={{ lineBreak: 'anywhere' }}
                    >
                      UIN:
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{
                      placeSelf: 'start',
                      justifyContent: isDesktop ? 'center' : 'end',
                      marginTop: '8px',
                    }}
                  >
                    <div
                      className="xxTitle text-noflow"
                      style={{ textAlign: 'end' }}
                    >
                      {data?.einvoice_irbm_uniqueID ??
                        data?.einvoice?.einvoice_irbm_uniqueID}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ placeSelf: 'start', marginTop: '8px' }}
                  >
                    <div
                      className="p-l-3 desc text-noflow"
                      style={{ lineBreak: 'anywhere' }}
                    >
                      Status:
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{
                      placeSelf: 'start',
                      justifyContent: isDesktop ? 'center' : 'end',
                      marginTop: '8px',
                    }}
                  >
                    <div
                      className="xxTitle text-noflow"
                      style={{ textAlign: 'end' }}
                    >
                      {status}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ placeSelf: 'start', marginTop: '8px' }}
                  >
                    <div
                      className="p-l-3 desc text-noflow"
                      style={{ lineBreak: 'anywhere' }}
                    >
                      Validated Date:
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{
                      placeSelf: 'start',
                      justifyContent: isDesktop ? 'center' : 'end',
                      marginTop: '8px',
                    }}
                  >
                    <div
                      className="xxTitle text-noflow"
                      style={{ textAlign: 'end' }}
                    >
                      {date === 'null' || date === null || date === undefined
                        ? `NA`
                        : einvDate}
                    </div>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
            {((!!data?.is_einvoice && !!data?.einvoice_qr_url) ||
              !!data?.einvoice?.einvoice_qr_url) && (
              <div
                style={{
                  height: 'auto',
                  margin: '0 auto',
                  maxWidth: 64,
                  width: '100%',
                  paddingTop: '10px',
                }}
              >
                <QRCode
                  size={256}
                  style={{
                    height: 'auto',
                    maxWidth: '100%',
                    width: '100%',
                  }}
                  value={
                    data?.einvoice_qr_url || data?.einvoice?.einvoice_qr_url
                  }
                  viewBox={`0 0 256 256`}
                />
              </div>
            )}
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () => setOpenDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
