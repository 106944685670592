import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { AcctPermission, ApprovalStatus } from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { ChangeEvent, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { WriteOffDisposalDetailContent } from '../WriteOffDisposalDetail/WriteOffDisposalDetailContent'

interface WriteOffDisposalProps {
  filteredList: any[]
  listLoading: boolean
  listStatus: string
  userList: any[]
  fetchMore: Function
  setOriginalListing: Function
}
export const WriteOffDisposalPostingList: React.FC<WriteOffDisposalProps> = ({
  filteredList,
  listLoading,
  listStatus,
  userList,
  fetchMore,
  setOriginalListing,
}) => {
  const { handlePermDisabled } = usePermissionChecker()
  const [postIDs, setPostIDs] = useState<Set<string>>(new Set())
  const [selectAll, setSelectAll] = useState(false)

  const sortedList = useMemo(() => {
    return filteredList.sort(
      (a, b) =>
        Object.values(ApprovalStatus).indexOf(a?.ApprovalStatus) -
        Object.values(ApprovalStatus).indexOf(b?.ApprovalStatus)
    )
  }, [filteredList])

  const handleCheckBoxChange = (
    e: ChangeEvent<HTMLInputElement>,
    AuditAdjustmentID: string
  ) => {
    const newSet = new Set(postIDs)
    if (e.target.checked) {
      newSet.add(AuditAdjustmentID)
    } else {
      newSet.delete(AuditAdjustmentID)
      setSelectAll(false)
    }
    setPostIDs(newSet)
  }

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setSelectAll(isChecked)

    const newSet = new Set(
      isChecked ? checkedFilteredList.map(el => el.AuditAdjustmentID) : []
    )
    setPostIDs(newSet)
  }

  const checkedFilteredList =
    filteredList && filteredList.length > 0 ? filteredList : []

  const onSubmitApprove = () => {
    console.log('Approving selected records:', Array.from(postIDs))
    // Implement approval logic
  }

  return (
    <>
      {listLoading && <Loading />}

      <ContentWrapper overflow>
        {listStatus === 'SUBMIT' && checkedFilteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              value=""
              color="primary"
              onChange={handleSelectAll}
              style={{ marginLeft: '-5px', marginTop: '-5px' }}
              checked={
                postIDs?.size > 0 && postIDs.size === checkedFilteredList.length
              }
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}

        <List className="core-list">
          {checkedFilteredList === undefined ||
          checkedFilteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={checkedFilteredList?.length}
              next={fetchMore}
              hasMore={true}
              style={{ padding: '8px 6px' }}
              loader={
                listLoading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {checkedFilteredList.map((el, index) => (
                <CardExpansion
                  key={index}
                  summary={
                    <ListItem>
                      {listStatus === 'SUBMIT' && (
                        <FormControl
                          component="fieldset"
                          style={{ marginLeft: '10p', marginRight: '-5px' }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={el.AuditAdjustmentID}
                                onChange={e =>
                                  handleCheckBoxChange(e, el.AuditAdjustmentID)
                                }
                                checked={postIDs.has(el.AuditAdjustmentID)}
                                color="primary"
                                disabled={handlePermDisabled({
                                  permEnum:
                                    AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
                                })}
                              />
                            }
                            label=""
                          />
                        </FormControl>
                      )}
                      <ListItemText
                        primary={
                          <>
                            {listStatus === 'SUBMIT' ? (
                              <>
                                <span className="xsTitle flex-space">
                                  {el?.Name}
                                </span>
                                <span className="xsTitle">{el?.AssetNo}</span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="flex-space mdLabel"
                                  style={{ fontSize: '12px' }}
                                >
                                  {el?.Name}
                                </span>
                                <span className="xsTitle">{el?.AssetNo}</span>
                              </>
                            )}
                          </>
                        }
                        secondary={
                          <>
                            <Grid container spacing={0}>
                              <Grid container className="table-content">
                                <Grid item xs={6}>
                                  <span className="desc flex-space">
                                    {el?.Date}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  style={{ textAlign: 'right' }}
                                >
                                  <span className="desc">
                                    <span style={{ color: 'orange' }}>
                                      {el?.DisposalQuantity}
                                    </span>
                                    /2 Disposal Qty
                                  </span>
                                </Grid>
                              </Grid>

                              <Grid container>
                                <Grid item xs={12}>
                                  <span className="desc">
                                    {el?.Description}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        }
                      />
                    </ListItem>
                  }
                >
                  <WriteOffDisposalDetailContent
                    listEl={el}
                    documentListing={el?.Attachment}
                    listStatus={'SUBMIT'}
                    userList={userList}
                    mode={'expansion'}
                  />
                </CardExpansion>
              ))}
            </InfiniteScroll>
          )}
        </List>
      </ContentWrapper>

      {listStatus === 'SUBMIT' && (
        <AccountFooter
          options={[
            {
              name: 'Reject',
              onClick: () => console.log('Open reject dialog'),
              color: 'secondary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    permEnum:
                      AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
                  }),
              },
            },
            {
              name: 'Approve',
              onClick: onSubmitApprove,
              color: 'primary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    permEnum:
                      AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
                  }),
              },
            },
          ]}
        />
      )}
    </>
  )
}
