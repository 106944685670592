import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  RecordStatus,
  useGetAccountPeriodLazyQuery,
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetEntityCoaLazyQuery,
  useGetMasterCoaCodeQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import * as yup from 'yup'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

interface TrailBalanceByCostCentreParamsFormProps {
  CompanyID: string
  Year: Number
  Period: Number
  CostCentreID: String
  AccountType: String
  MasterCOAID: String
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const TrailBalanceByCostCentreParamsForm = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const latestCompany = localStorage.getItem('latestCompany')
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
    Year: CommonYupValidation.requireField('Year is required'),
    Period: CommonYupValidation.requireField('Period is required'),
  })
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    TrailBalanceByCostCentreParamsFormProps
  >({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    variables: { CompanyID },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadAccountPeriod({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'FYear',
          },
        })

        loadCostCentre({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'Code',
            IsLastNode: true,
          },
        })

        loadEntityCOA({
          variables: { CompanyID: latestCompany },
        })
      }
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadAccountPeriod({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'FYear',
          },
        })

        loadCostCentre({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'Code',
            IsLastNode: true,
          },
        })

        loadEntityCOA({
          variables: { CompanyID: latestCompany },
        })
      }
    },
  })

  const [
    loadAccountPeriod,
    {
      loading: AccountPeriodLoading,
      data: { getAccountPeriod } = { getAccountPeriod: [] },
    },
  ] = useGetAccountPeriodLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, orderByAsc: 'FYear' },
  })

  const [
    loadCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadEntityCOA,
    {
      loading: EntityCOALoading,
      data: { getEntityCOA } = { getEntityCOA: [] },
    },
  ] = useGetEntityCoaLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  const {
    loading: MasterCOALoading,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCodeQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
  })

  const masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA
          ?.map(coa => coa?.MasterCOA)
          ?.filter(
            coa =>
              coa?.IsLastNode === true &&
              coa?.IsControl === false &&
              coa?.RecordStatus === RecordStatus.Active
          )
      : getMasterCOA?.filter(
          coa =>
            coa?.IsLastNode === true &&
            coa?.IsControl === false &&
            coa?.RecordStatus === RecordStatus.Active
        )

  const [selectedDepartment, setSelectedDepartment] = useState(new Set())

  const handleCheckboxDepartmentChange = (event: any) => {
    let dep = selectedDepartment

    if (event?.target?.checked) {
      dep.add(event?.target?.value)
    } else {
      dep.delete(event?.target?.value)
    }
    setSelectedDepartment(new Set(dep))
  }

  const accountType = [
    { name: 'Asset', value: 'ASSET' },
    { name: 'Liability', value: 'LIABILITY' },
    { name: 'Equity', value: 'EQUITY' },
    { name: 'Revenue', value: 'REVENUE' },
    { name: 'Expense', value: 'EXPENSE' },
  ]

  const [selectedAccountType, setSelectedAccountType] = useState(new Set())

  const handleCheckboxAccountTypeChange = (event: any) => {
    let type = selectedAccountType

    if (event?.target?.checked) {
      type.add(event?.target?.value)
    } else {
      type.delete(event?.target?.value)
    }
    setSelectedAccountType(new Set(type))
  }

  const [selectedAccountCode, setSelectedAccountCode] = useState(new Set())

  const handleCheckboxAccountCodeChange = (event: any) => {
    let code = selectedAccountCode

    if (event?.target?.checked) {
      code.add(event?.target?.value)
    } else {
      code.delete(event?.target?.value)
    }
    setSelectedAccountCode(new Set(code))
  }

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/digital-reports/general-ledger/trial-balance-costcentre-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        Year: Number(data.Year),
        Period: Number(data.Period),
        CostCentreID: selectedDepartment
          ? Array.from(selectedDepartment)?.toString()
          : undefined,
        AccountType: selectedAccountType
          ? Array.from(selectedAccountType)?.toString()
          : undefined,
        MasterCOAID: selectedAccountCode
          ? Array.from(selectedAccountCode)?.toString()
          : undefined,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {EntityCOALoading && <Loading />}
      {MasterCOALoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {AccountPeriodLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/general-ledger`)}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Trial Balance By Department', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !CompanyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]

                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CompanyID', newValue?.CompanyID)

                      loadAccountPeriod({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                          orderByAsc: 'FYear',
                        },
                      })

                      loadCostCentre({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                          orderByAsc: 'Code',
                          IsLastNode: true,
                        },
                      })

                      loadEntityCOA({
                        variables: { CompanyID: newValue?.CompanyID },
                      })
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.Name}</span>
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.map(el => el?.FYear)
                      .filter((element, index) => {
                        return (
                          getAccountPeriod
                            ?.map(el => el?.FYear)
                            .indexOf(element) === index
                        )
                      })
                      .sort((a, b) => b - a) || []
                  }
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Year', newValue)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          error={errors?.Year ? true : false}
                          label="Year"
                          style={{ width: '100%' }}
                          margin="normal"
                          required
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Year"
            name="Year"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            error={errors?.Year ? true : false}
            required
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.filter(x => x?.FYear === Number(watch('Year')))
                      .sort((a, b) => b.FPeriod - a.FPeriod) || []
                  }
                  getOptionLabel={option => `${option?.FPeriod}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Period', newValue?.FPeriod)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.FPeriod}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          error={errors?.Period ? true : false}
                          label="Period"
                          style={{ width: '100%' }}
                          margin="normal"
                          required
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Period"
            name="Period"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            error={errors?.Period ? true : false}
            required
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={getCostCentre || []}
                  getOptionLabel={option => `${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.CostCentreID)
                    })

                    setSelectedDepartment(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CostCentreID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxDepartmentChange(event)
                        }}
                      />
                      {`${option?.Code}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Department"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="CostCentreID"
            label="Department"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={accountType || []}
                  getOptionLabel={option => `${option?.name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.value)
                    })

                    setSelectedAccountType(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.value}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxAccountTypeChange(event)
                        }}
                      />
                      {`${option?.name}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Account Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="AccountType"
            label="Account Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={masterCOAList || []}
                  getOptionLabel={option => `${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.MasterCOAID)
                    })

                    setSelectedAccountCode(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.MasterCOAID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxAccountCodeChange(event)
                        }}
                      />
                      {`${option?.Code}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Account Code"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="MasterCOAID"
            label="Account Code"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
