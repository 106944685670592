import companyRoutes from 'containers/CompanyModule/CompanyRoutes'
import stockItemRoutes from 'containers/GeneralSettingModule/StockItem/StockItemRoutes'
import workdeskRoutes from 'containers/WorkdeskModule/WorkdeskRoutes'
import React, { lazy } from 'react'
import generalApprovalRoutes from './ApprovalPolicy/ApprovalPolicyGPRoutes'
import approvalPolicyRoutes from './ApprovalPolicy/ApprovalPolicyRoutes'
import assetsAdditionalFieldSettingRoutes from './AssetsAdditionalFieldSetting/AssetsAdditionalFieldSettingRoutes'
import assetsCategoryRoutes from './AssetsCategory/AssetsCategoryRoutes'
import assetsLocationRoutes from './AssetsLocation/AssetsLocationRoutes'
import assetsSourceRoutes from './AssetSource/AssetSourceRoutes'
import autoNumberingRoutes from './AutoNumberingwDnD/AutoNumberingRoutes'
import bankProfileRoutes from './BankProfile/BankProfileRoutes'
import bulkPurchasePriceRoutes from './BulkPurchasePrice/BulkPurchasePriceRoutes'
import costCentreDefinitionRoutes from './CostCentreDefinition/CostCentreDefinitionRoutes'
import costCentrTypeRoutes from './CostCentreType/CostCentreTypeRoutes'
import creditCardTypeRoutes from './CreditCardType/CreditCardRoutes'
import creditorProfileRoutes from './CreditorProfile/CreditorProfileRoutes'
import creditorTypeRoutes from './CreditorType/CreditorTypeRoutes'
import currencyRoutes from './Currency/CurrencyRoutes'
import currencyExchangeRoutes from './CurrencyExchange/CurrencyExchangeRoutes'
import depreciationMethodRoutes from './DepreciationMethod/DepreciationMethodRoutes'
import eLetteringRoute from './ELettering/ELetteringRoutes'
import expenseClassRoutes from './ExpenseClass/ExpenseClassRoutes'
import expenseItemRoutes from './ExpenseItem/ExpenseItemRoutes'
import financialReportRoutes from './FinancialReport/FinancialReportRoutes'
import inventoryPolicyRoutes from './InventoryPolicy/InventoryPolicyRoutes'
import masterCOARoutes from './MasterCOA/MasterCOARoutes'
import paymentMethodRoutes from './PaymentMethod/PaymentMethodRoutes'
import policySettingsRoutes from './PolicySettings/PolicySettingsRoutes'
import itemCategoryRoutes from './PurchaseClass/PurchaseClassRoutes'
import itemRoutes from './PurchaseItem/PurchaseItemRoutes'
import purchaseVarianceRoutes from './PurchaseItemVariancePolicy/PurchaseItemVariancePolicyRoutes'
import stockAdjustmentReasonRoute from './StockAdjustmentReason/StockAdjustmentReasonRoute'
import stockCategoryForm from './StockCategory/StockCategoryRoute'
import supplierCategoryRoutes from './SupplierCategory/SupplierCategoryRoutes'
import supplierProfileRoutes from './SupplierProfile/SupplierProfileRoutes'
import transactionTypeRoutes from './TransactionType/TransactionTypeRoutes'
import warehouseRoutes from './Warehouse/WarehouseRoute'

const GeneralSettingListing = lazy(() =>
  import('./GeneralSettingListing').then(module => ({
    default: module.GeneralSettingListing,
  }))
)

const GeneralPurchasePolicyForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralPurchasePolicy/GeneralPurchasePolicyForm'
  ).then(module => ({
    default: module.GeneralPurchasePolicyForm,
  }))
)

const generalSettingRoutes = [
  {
    props: { exact: true, path: '/general-setting' },
    component: <GeneralSettingListing />,
  },
  {
    props: { exact: true, path: '/:mode/general-setting' },
    component: <GeneralSettingListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/general-purchase-policy',
    },
    component: <GeneralPurchasePolicyForm />,
  },
  ...masterCOARoutes,
  ...approvalPolicyRoutes,
  ...bankProfileRoutes,
  ...currencyRoutes,
  ...currencyExchangeRoutes,
  ...companyRoutes,
  ...autoNumberingRoutes,
  ...costCentreDefinitionRoutes,
  ...creditCardTypeRoutes,
  ...paymentMethodRoutes,
  ...workdeskRoutes,
  ...expenseClassRoutes,
  ...expenseItemRoutes,
  ...policySettingsRoutes,
  ...creditorTypeRoutes,
  ...creditorProfileRoutes,
  ...financialReportRoutes,
  ...costCentrTypeRoutes,
  ...warehouseRoutes,
  ...stockItemRoutes,
  ...stockAdjustmentReasonRoute,
  ...stockCategoryForm,
  ...inventoryPolicyRoutes,
  ...transactionTypeRoutes,
  // General Purchase Routes
  ...bulkPurchasePriceRoutes,
  ...supplierCategoryRoutes,
  ...itemRoutes,
  ...generalApprovalRoutes,
  ...itemCategoryRoutes,
  ...supplierProfileRoutes,
  ...purchaseVarianceRoutes,
  ...eLetteringRoute,
  // Fixed Asset Routes
  ...assetsLocationRoutes,
  ...assetsSourceRoutes,
  ...assetsCategoryRoutes,
  ...depreciationMethodRoutes,
  ...assetsAdditionalFieldSettingRoutes,
]

export default generalSettingRoutes
