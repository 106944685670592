import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  useCancelRecurringJournalMutation,
  useDeleteRecurringJournalMutation,
  useGetDocNumTitleQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  formatDate,
  formatMonth,
  formatTime,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'
/* -------------------------------------------- */
/*            For ACTIVE and APPROVED           */
/* -------------------------------------------- */

export const RecurringEntryList = (props: any) => {
  const {
    filteredList,
    listLoading,
    listStatus,
    setOriginalListing,
    fetchMore,
    handleFilterDate,
  } = props
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [cancelRecurJournal, setCancelRecurJournal] = useState(false)
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const [
    cancelRecurringJournal,
    {
      loading: cancelRecurringJournalLoading,
      error: cancelRecurringJournalError,
    },
  ] = useCancelRecurringJournalMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.cancelRecord())
      console.log('data', data)
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/${CompanyID}/recurring-journal`,
        })
      }, 500)
      setOriginalListing([])
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr: [listStatus, ApprovalStatus.Cancelled],
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name === '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  //search part -----------------------------------------

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()

  const {
    loading,
    error,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
  })

  const handleCancel = data => {
    cancelRecurringJournal({
      variables: {
        RecurringJournalID: menu?.obj?.RecurringJournalID,
      },
    })
    setCancelRecurJournal(false)
  }

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'GL_RecurringJournal',
    },
  })

  const docNumChecker = getDocumentNumberHeader?.length === 0

  /** DELETE FUNCTION AND POPUP TRIGGER **/

  const [deleteRJ, setDeleteRJ] = useState(false)
  const [deleteError, setDeleteErrorPayment] = useState(false)

  const handleClickDeleteErrorRJDialog = () => {
    setDeleteErrorPayment(true)
    handleClose()
  }

  const [
    deleteRecurringJournal,
    {
      loading: deleteRecurringJournalLoading,
      error: deleteRecurringJournalError,
    },
  ] = useDeleteRecurringJournalMutation({
    onError: error => {
      if (error.message.includes('FK_')) {
        handleClickDeleteErrorRJDialog()
      }
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOriginalListing([])
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr:
            listStatus === 'COMPLETED'
              ? [listStatus, ApprovalStatus.Cancelled]
              : [listStatus],
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name === '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const handleDelete = data => {
    console.log('delete')
    deleteRecurringJournal({
      variables: {
        RecurringJournalID: data,
      },
    })
    setDeleteRJ(false)
  }

  const handleClickCancelRecurringJournalDialog = () => {
    setCancelRecurJournal(true)
    handleClose()
  }
  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  return (
    <>
      {loading && <Loading />}
      {listLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {cancelRecurringJournalLoading && <Loading />}
      {deleteRecurringJournalLoading && <Loading />}
      <ContentWrapper advSearch float overflow>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr:
                      listStatus === 'COMPLETED'
                        ? [listStatus, ApprovalStatus.Cancelled]
                        : [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name === '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                listLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)
                ?.filter(handleFilterDate)
                ?.sort((a, b) => {
                  {
                    if (listStatus == 'COMPLETED') {
                      return b.ApprovalStatus.localeCompare(a.ApprovalStatus)
                    } else {
                      return a.createdTs.localeCompare(b.createdTs)
                    }
                  }
                })
                ?.map((el, index) => {
                  return (
                    <ListItem
                      style={{
                        backgroundColor:
                          el.ApprovalStatus === ApprovalStatus.Cancelled
                            ? '#eaeaea'
                            : null,
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            <span
                              className=" mdLabel"
                              style={{ fontSize: '12px' }}
                            >
                              {el?.RecurringJournalStartDate.map(entry => (
                                <span key={entry.StartDate}>
                                  {formatMonth(entry.StartDate)}
                                </span>
                              ))}
                            </span>

                            <LibraryBooksIcon
                              style={{
                                fontSize: 'small',
                                width: '20px',
                                marginLeft: '5px',
                              }}
                            />
                            <span
                              className="flex-space mdLabel"
                              style={{ fontSize: '12px' }}
                            >
                              {el?.NoOfRecurrance}
                            </span>
                            <span className="xxTitle">
                              <span className="c-orange">
                                <span style={{ fontSize: '12px' }}>
                                  {amtStr(
                                    el?.RecurringJournalItem?.filter(
                                      item => item?.DocAmt > 0
                                    )?.reduce((x, y) => x + y?.DocAmt, 0)
                                  )}
                                </span>
                              </span>
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            <span
                              className="desc"
                              style={{
                                whiteSpace: 'break-spaces',
                                lineBreak: 'auto',
                              }}
                            >
                              <GreyTooltip
                                disableFocusListener
                                title={
                                  <React.Fragment>
                                    <Grid container wrap="nowrap" spacing={2}>
                                      <Grid item>
                                        <Avatar
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        >
                                          <PersonIcon
                                            style={{ fontSize: 'small' }}
                                          />
                                        </Avatar>
                                      </Grid>
                                      <Grid item xs zeroMinWidth>
                                        <div style={{ fontSize: '12px' }}>
                                          {getUsersByAccountAndSoftware?.find(
                                            x =>
                                              x?.ID ===
                                              (listStatus === 'ACTIVE'
                                                ? el?.createdBy
                                                : listStatus === 'REJECTED'
                                                ? el?.modBy
                                                : el?.approvedBy)
                                          )?.name ??
                                            getUsersByAccountAndSoftware?.find(
                                              x =>
                                                x?.ID ===
                                                (listStatus === 'ACTIVE'
                                                  ? el?.createdBy
                                                  : listStatus === 'REJECTED'
                                                  ? el?.modBy
                                                  : el?.approvedBy)
                                            )?.name}
                                        </div>
                                        <div style={{ fontSize: '10px' }}>
                                          {formatMonth(
                                            listStatus === 'ACTIVE'
                                              ? el?.createdTs
                                              : listStatus === 'SUBMITTED'
                                              ? el?.submittedTs
                                              : listStatus === 'REJECTED'
                                              ? el?.modTs
                                              : el?.approvedTs
                                          )}
                                          &nbsp;
                                          {formatTime(
                                            listStatus === 'ACTIVE'
                                              ? el?.createdTs
                                              : listStatus === 'SUBMITTED'
                                              ? el?.submittedTs
                                              : listStatus === 'REJECTED'
                                              ? el?.modTs
                                              : el?.approvedTs
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                }
                                arrow
                                enterTouchDelay={0}
                              >
                                <div>
                                  <IconText
                                    icon={
                                      <img
                                        src={SubmitterLog}
                                        style={{
                                          width: '12px',
                                          marginRight: '3px',
                                        }}
                                      />
                                    }
                                    font="desc"
                                  ></IconText>
                                </div>
                              </GreyTooltip>
                            </span>
                            <span
                              className="desc flex-space text-overflow"
                              style={{ marginBottom: '-5px' }}
                            >
                              {el?.Description}
                            </span>
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e =>
                            handleClick(e, el?.RecurringJournalID, index, el)
                          }
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerRecurringJournalCreate,
            })}
            onClick={() => {
              if (docNumChecker) {
                setOpenSnackBar(true)
                setSnackBarMsg(SystemMsgs.errorNumberingStructure())
              } else {
                history.push(
                  `/general-ledger/${CompanyID}/recurring-journal/add`
                )
              }
            }}
          />
        )}
      </ContentWrapper>
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {listStatus === 'ACTIVE' ? (
          <>
            <MenuItem
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum: AcctPermission.GeneralLedgerJournalProcessingUpdate,
              })}
              onClick={() => {
                history.push({
                  pathname: `/general-ledger/${CompanyID}/recurring-journal/${menu?.obj?.RecurringJournalID}/edit`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">Edit</span>
            </MenuItem>
            <MenuItem
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum: AcctPermission.GeneralLedgerJournalProcessingDelete,
              })}
              onClick={() => setDeleteRJ(true)}
            >
              <span className="">Delete</span>
            </MenuItem>
          </>
        ) : listStatus === 'COMPLETED' ? (
          <>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/general-ledger/${CompanyID}/recurring-journal/${menu?.obj?.RecurringJournalID}/detail`,
                  state: menu.obj,
                })
              }}
            >
              <span className="mdDesc">View</span>
            </MenuItem>

            <MenuItem
              onClick={() => {
                {
                  history.push(
                    `/general-ledger/${CompanyID}/recurring-journal/${menu?.obj.RecurringJournalID}/journal-listing`
                  )
                }
              }}
            >
              <span className="mdDesc">
                Journals {menu?.obj?.GeneratedJournals?.length ?? 0}/
                {menu?.obj?.NoOfRecurrance}
              </span>
            </MenuItem>

            {menu?.obj?.ApprovalStatus === 'COMPLETED' ? (
              <MenuItem
                onClick={handleClickCancelRecurringJournalDialog}
                disabled={
                  menu?.obj?.GeneratedJournals?.length ===
                    menu?.obj?.NoOfRecurrance ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.GeneralLedgerRecurringJournalCancel,
                  })
                }
              >
                <span className="mdDesc">Cancel Recurring</span>
              </MenuItem>
            ) : null}
          </>
        ) : (
          <>
            <MenuItem
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum: AcctPermission.GeneralLedgerJournalProcessingUpdate,
              })}
              onClick={() => {
                history.push({
                  pathname: `/general-ledger/${CompanyID}/recurring-journal/${menu?.obj?.RecurringJournalID}/edit`,
                  state: { ...menu?.obj, mode: 'resubmit' },
                })
              }}
            >
              <span className="">Resubmit</span>
            </MenuItem>
          </>
        )}
      </Menu>

      <CommonDialog
        fullWidth={true}
        open={cancelRecurJournal}
        onClose={() => setCancelRecurJournal(false)}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div
                    className="title"
                    style={{
                      color: '#ff9800',
                      marginBottom: '2px',
                      fontSize: '14px',
                    }}
                  >
                    Recurring Journal
                  </div>
                </div>
                <div>
                  <div className="infoline-content">
                    <>
                      <div className="mdLabel">
                        {formatDate(menu?.obj?.createdTs)}
                      </div>
                      <div className="flex-space"></div>
                      <div className="xsTitle rightText">Cancel </div>
                    </>
                  </div>
                </div>
              </div>
            ),
          },

          body: () => (
            <div className="content-wrap full">
              <span className="mdDesc full">Confirm to cancel?</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setCancelRecurJournal(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleCancel(menu?.obj?.RecurringJournalID),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deleteRJ}
        setOpenDeleteDialog={setDeleteRJ}
        title={'Journal'}
        menu={menu?.obj}
        handleDelete={handleDelete}
        passID={menu?.ID}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
