import theme from '@ifca-root/react-component/src/assets/theme'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { default as React, useEffect } from 'react'

const dummyStockItems = [
  {
    Name: 'Kawasaki',
    Counts: { current: '3 Unit /', total: '3 Unit' },
    Department: 'Department',
    Location: 'Location',
  },
  {
    Name: 'Lorry',
    Counts: { current: '6 Unit /', total: '5 Unit' },
    Department: 'Department',
    Location: 'Location',
  },
  {
    Name: 'Van',
    Counts: { current: '11 Unit /', total: '6 Unit' },
    Department: 'Department',
    Location: 'Location',
  },
]

export const AssetsCountDetailitemContent = (props: any) => {
  useEffect(() => {
    console.log('AssetsCountDetailitemContent')
  }, [])

  const { listItem = dummyStockItems } = props
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <>
      <div className="rm-padding table-wrap">
        <List className="core-list content-wrap full" disablePadding>
          {listItem?.map((el, index) => (
            <ListItem
              className="table-listItem"
              key={index}
              divider
              style={{ backgroundColor: 'white' }}
            >
              <ListItemText
                primary={
                  <Grid
                    container
                    justifyContent="flex-start"
                    spacing={0}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={8}
                      style={{ placeSelf: 'start', display: 'flex' }}
                    >
                      <div
                        className="xsTitle"
                        style={{ lineBreak: 'anywhere' }}
                      >
                        {index + 1}. {el.Name}
                      </div>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={6}>
                        <span style={{ fontSize: '12px' }}>
                          {el.Department}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          textAlign: 'right',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ fontSize: '12px', marginRight: '4px' }}>
                          {el.Counts.current}
                        </span>
                        <span
                          style={{
                            fontSize: '12px',
                            marginRight: '4px',
                            color: '#F39C12',
                          }}
                        >
                          {el.Counts.total}
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={1}>
                      <LocationOnIcon
                        style={{
                          fontSize: 16,
                          marginRight: 4,
                          color: '#F39C12',
                        }}
                      />
                      <span style={{ fontSize: '12px' }}>{el.Location}</span>
                    </Grid>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div
        className="footer-label"
        style={{
          background: '#bfd3ff',
        }}
      ></div>
    </>
  )
}
