import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Autocomplete } from '@material-ui/lab'
import {
  RecordStatus,
  useGetClassificationCodeQuery,
  useGetDepartmentListingForFormLazyQuery,
  useGetTaxSchemeQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import BigNumber from 'bignumber.js'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Grid, TextField } from '@material-ui/core'
import {
  amtNumStr,
  amtStr,
} from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { getTotalAmt } from 'containers/ARAPModule/Helper/AmountCalculation'
import NumberFormat from 'react-number-format'
import { ItemVoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomItemTextField'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'

interface ARDebitNoteItemProps {
  BillItemID: string
  CostCentreID: string
  Amount: number
  TaxSchemeID: string
  TaxAmt: number
  TaxRate: number
  DocAmt: number
  Remark: string
  einvoice_classification_code_id: string
}

export const ARDebitNoteItemDialog = (props: any) => {
  const {
    menu,
    watch,
    CompanyID,
    getBillItem,
    openItemDialog,
    BillItemLoading,
    taxEffectiveDate,
    setOpenItemDialog,
    debitNoteItemData,
  } = props

  const DebitNoteItemFormSchema = yup.object().shape({
    BillItemID: CommonYupValidation.requireField('Bill Item is required'),
    CostCentreID: CommonYupValidation.requireField('Department is required'),
    Amount: CommonYupValidation.requireField('Amount is required'),
    Remark: CommonYupValidation.requireField('Remark is required'),
  })

  /* -------------------------------------------- */
  /*                  USESTATE                    */
  /* -------------------------------------------- */
  const [selectedTax, setSelectedTax] = useState(null)
  const [mountDefVal, setMountDefVal] = useState(!!menu?.obj)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [taxRate, setTaxRate] = useState(0.0)
  const [eInvoiceItemName, setEInvoiceItemName] = useState(null)
  const [itemRecord, setItemRecord] = useState(false)
  const [itemVoiceRemark, setItemVoiceRemark] = useState('')
  const [taxAmt, setTaxAmt] = useState(!!menu?.obj ? menu?.obj?.TaxAmt : 0)
  const [cost, setCost] = useState(
    !!menu?.obj ? menu?.obj?.DocAmt - menu?.obj?.TaxAmt : 0
  )
  const [classificationCode, setClassificationCode] = useState(
    !!menu?.obj ? menu?.obj?.einvoice_classification_code_id : null
  )

  /* -------------------------------------------- */
  /*                  USE FORM                    */
  /* -------------------------------------------- */
  const {
    handleSubmit: handleSubmitItem,
    register: registerItem,
    errors: errorsItem,
    control: controlItem,
    getValues: getValuesItem,
    watch: watchItem,
    setValue: setValueItem,
    clearErrors: clearErrorsItem,
  } = useForm<ARDebitNoteItemProps>({
    mode: 'onSubmit',
    resolver: yupResolver(DebitNoteItemFormSchema),
  })

  /* -------------------------------------------- */
  /*                   QUERIES                    */
  /* -------------------------------------------- */
  const {
    loading: TaxSchemeLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: { RecordStatus: RecordStatus.Active },
    onCompleted: () => {
      if (!!menu?.obj) {
        setSelectedTax(
          taxScheme?.find(x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID)
        )
      }
      if (!!menu?.obj && mountDefVal === true) {
        fetchDepartmentListingForForm({
          variables: { CompanyID, BillItemID: menu?.obj?.BillItemID },
        })
      }
    },
  })

  const {
    loading: ClassificationCodeLoading,
    data: { getClassificationCode } = { getClassificationCode: [] },
  } = useGetClassificationCodeQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getClassificationCode }) => {
      if (watch('is_einvoice') === true) {
        setClassificationCode(
          !!menu?.obj?.einvoice_classification_code_id
            ? menu?.obj?.einvoice_classification_code_id
            : null
        )
        if (menu?.obj?.einvoice_classification_code_id != null) {
          setEInvoiceItemName(
            getClassificationCode?.filter(
              x => x?.id === menu?.obj?.einvoice_classification_code_id
            )[0].classificationCode +
              ` (` +
              getClassificationCode?.filter(
                x => x?.id === menu?.obj?.einvoice_classification_code_id
              )[0].description +
              `)`
          )
        }
      }
    },
  })

  const [
    fetchDepartmentListingForForm,
    {
      loading: HandlerLoading,
      data: { getDepartmentListingForForm } = {
        getDepartmentListingForForm: [],
      },
    },
  ] = useGetDepartmentListingForFormLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getDepartmentListingForForm }) => {
      if (!!getDepartmentListingForForm[0]?.IsDefault) {
        setValueItem(
          'CostCentreID',
          getDepartmentListingForForm[0]?.CostCentreID
        )
      }
      if (!!menu?.obj?.CostCentreID && mountDefVal === true) {
        setValueItem('CostCentreID', menu?.obj?.CostCentreID)
      }
    },
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(`${errorMessage}"`)
    },
  })

  const taxScheme: any = getTaxScheme
  const taxCodeName = getTaxScheme?.filter(
    bank => bank?.TaxSchemeID === selectedTax?.TaxSchemeID
  )[0]?.Code
  const taxCodeRate = amtStr(
    getTaxScheme?.filter(
      bank => bank?.TaxSchemeID === selectedTax?.TaxSchemeID
    )[0]?.LatestTax?.Rate
  )
  const costCentreCode =
    watchItem('CostCentreID') !== undefined
      ? getDepartmentListingForForm?.find(
          x => x?.CostCentreID === watchItem('CostCentreID')
        )?.Code
      : getDepartmentListingForForm?.find(
          x => x?.CostCentreID === menu?.obj?.CostCentreID
        )?.Code

  const billItemName =
    watchItem('BillItemID') !== undefined
      ? getBillItem.filter(x => x?.BillItemID === watchItem('BillItemID'))[0]
          ?.Name
      : getBillItem.filter(x => x?.BillItemID === menu?.obj?.BillItemID)[0]
          ?.Name

  /* -------------------------------------------- */
  /*             ONCREATE & ONUPDATE              */
  /* -------------------------------------------- */
  const onCreateDebitNoteItem = data => {
    debitNoteItemData.push({
      DebitNoteItemID: data?.DebitNoteItemID,
      BillItemName: billItemName,
      BillItemID: data?.BillItemID,
      CostCentreName: costCentreCode,
      CostCentreID: data?.CostCentreID,
      Amount: parseFloat(amtNumStr(data?.Amount)),
      Sequence: debitNoteItemData?.length + 1,
      TaxSchemeID: data?.TaxSchemeID,
      TaxCode: taxCodeName,
      TaxRate: taxCodeRate,
      TaxAmt: data?.TaxAmt,
      DocAmt: getTotalAmt(true, cost, taxAmt, taxRate),
      Remark: data?.Remark,
      einvoice_classification_code_id: classificationCode,
    })
    setOpenItemDialog(false)
  }

  const onUpdateDebitNoteItem = (data, index) => {
    debitNoteItemData[index] = {
      DebitNoteItemID: data.DebitNoteItemID,
      BillItemName: billItemName,
      BillItemID: data.BillItemID,
      CostCentreName: costCentreCode,
      CostCentreID: data.CostCentreID,
      Amount: parseFloat(amtNumStr(data?.Amount)),
      Sequence: data?.Sequence,
      TaxCode: taxCodeName,
      TaxRate: data?.TaxRate || taxCodeRate,
      TaxSchemeID:
        data?.TaxSchemeID ||
        getBillItem?.filter(x => x?.TaxSchemeID === data?.TaxSchemeID)[0]
          ?.TaxSchemeID,
      TaxAmt: data.TaxAmt,
      DocAmt: getTotalAmt(true, cost, taxAmt, taxRate),
      Remark: data.Remark,
      einvoice_classification_code_id: classificationCode,
    }
    setOpenItemDialog(false)
  }

  /* -------------------------------------------- */
  /*                  FUNCTION                    */
  /* -------------------------------------------- */
  const handleBillItemChange = BillItemID => {
    setValueItem('BillItemID', BillItemID)
    let defTax = getBillItem?.filter(x => x?.BillItemID === BillItemID)[0]
      ?.TaxSchemeID
    let selectedTax = taxScheme.find(x => x?.TaxSchemeID === defTax)
    setValueItem('TaxSchemeID', defTax)
    setSelectedTax(selectedTax)
    clearErrorsItem('BillItemID')

    const taxAmt = new BigNumber(
      selectedTax?.LatestTax === null
        ? selectedTax?.TaxEffective.reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          })?.Rate
        : selectedTax?.TaxEffective?.filter(
            x => new Date(x?.Date) <= new Date(taxEffectiveDate)
          ).reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          }, 0)?.Rate
    )
      .dividedBy(100)
      .multipliedBy(cost)
      .toNumber()
    setTaxAmt(taxAmt)
    setValueItem('TaxAmt', taxAmt)
  }

  const handleAmtChange = event => {
    setCost(event.value)

    if (selectedTax) {
      setTaxAmt(
        new BigNumber(
          selectedTax?.LatestTax === null
            ? selectedTax?.TaxEffective?.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
            : selectedTax?.LatestTax?.Rate
        )
          .dividedBy(100)
          .multipliedBy(event.value)
          .toNumber()
      )
    }
  }

  const handleTaxAmtChange = event => {
    setTaxAmt(event.value)
  }

  const checkClassificationCode = billItem => {
    if (
      billItem?.einvoice_classification_code_id !== null &&
      billItem?.einvoice_classification_code_id !== undefined &&
      billItem?.einvoice_classification_code_id !== ''
    )
      return true
    else return false
  }

  /* -------------------------------------------- */
  /*                  USEEFFECT                   */
  /* -------------------------------------------- */
  useEffect(() => {
    if (!isNaN(taxAmt)) {
      setValueItem('TaxAmt', taxAmt)
      clearErrorsItem('TaxAmt')
    }
  }, [taxAmt])

  useEffect(() => {
    if (!!taxScheme) {
      // set tax on edit
      setSelectedTax(
        taxScheme?.find(x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID)
      )

      setTaxRate(
        taxScheme
          .filter(x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID)[0]
          ?.TaxEffective?.filter(
            x => new Date(x?.Date) <= new Date(taxEffectiveDate)
          )
          .reduce((a, b) => {
            return new Date(a.Date) > new Date(b.Date) ? a : b
          })?.Rate
      )
    }
  }, [taxScheme])

  // useEffect(() => {
  //   if (watch('is_einvoice') === true) {
  //     setClassificationCode(
  //       !!menu?.obj?.einvoice_classification_code_id
  //         ? menu?.obj?.einvoice_classification_code_id
  //         : null
  //     )
  //     if (menu?.obj?.einvoice_classification_code_id != null) {
  //       setEInvoiceItemName(
  //         getClassificationCode?.filter(
  //           x => x?.id === menu?.obj?.einvoice_classification_code_id
  //         )[0].classificationCode +
  //           ` (` +
  //           getClassificationCode?.filter(
  //             x => x?.id === menu?.obj?.einvoice_classification_code_id
  //           )[0].description +
  //           `)`
  //       )
  //     }
  //   }
  // }, [menu?.obj])

  return (
    <>
      {HandlerLoading && <Loading />}
      {BillItemLoading && <Loading />}
      {TaxSchemeLoading && <Loading />}
      {ClassificationCodeLoading && <Loading />}
      <CommonDialog
        fullWidth={true}
        open={openItemDialog}
        onClose={() => {
          setOpenItemDialog(false)
          setTaxAmt(null)
          setSelectedTax(null)
        }}
        sections={{
          header: {
            dynamic: (
              <div>
                <div className="dialog-dynamic-content">
                  <div className="session">
                    <div className="flex session">
                      <div
                        className="dialog-title flex-space"
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: 'orange',
                        }}
                      >
                        {'Debit Note Detail'}
                      </div>
                      <div
                        className="dialog-title right-text"
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: 'orange',
                        }}
                      >
                        {menu?.obj ? 'Edit' : 'New'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="infoline-content" />
              </div>
            ),
          },
          body: () => (
            <>
              <div className="content-container" style={{ marginTop: '-20px' }}>
                <Controller
                  render={({ onChange, onBlur, value }) => {
                    const defVal = getBillItem?.filter(
                      x => x?.BillItemID === menu?.obj?.BillItemID
                    )[0]

                    return (
                      <Autocomplete
                        options={
                          watch('is_einvoice') === true
                            ? getBillItem.filter(
                                item => item.einv_integrated === true
                              )
                            : getBillItem?.sort((a, b) => {
                                return a.Name.localeCompare(b.Name)
                              }) || []
                        }
                        getOptionLabel={option =>
                          `${option?.Name} | ${option?.Description}`
                        }
                        fullWidth
                        onChange={(value, newValue: any) => {
                          handleBillItemChange(newValue?.BillItemID)
                          setValueItem('BillItemID', newValue?.BillItemID)
                          setValueItem('CostCentreID', null)
                          fetchDepartmentListingForForm({
                            variables: {
                              CompanyID,
                              BillItemID: newValue?.BillItemID,
                            },
                          })
                          if (checkClassificationCode(newValue) === true) {
                            setClassificationCode(
                              newValue?.einvoice_classification_code_id
                            )
                            if (
                              newValue?.einvoice_classification_code_id !== null
                            ) {
                              setEInvoiceItemName(
                                getClassificationCode?.filter(
                                  x =>
                                    x?.id ===
                                    newValue?.einvoice_classification_code_id
                                )[0].classificationCode +
                                  ` (` +
                                  getClassificationCode?.filter(
                                    x =>
                                      x?.id ===
                                      newValue?.einvoice_classification_code_id
                                  )[0].description +
                                  `)`
                              )
                            }
                          }
                          setMountDefVal(false)
                        }}
                        renderOption={(props, option) => {
                          return (
                            <div>
                              <div>
                                <span className="xsTitle">{props?.Name}</span>
                              </div>
                              <div className="desc">{props?.Description}</div>
                            </div>
                          )
                        }}
                        defaultValue={defVal}
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                required
                                label="Bill Item"
                                variant="standard"
                                fullWidth
                                name="BillItemID"
                                value={value}
                                helperText={errorsItem?.BillItemID?.message}
                                error={errorsItem?.BillItemID ? true : false}
                                defaultValue={menu?.obj?.BillItemID || ''}
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  name="BillItemID"
                  autoComplete="off"
                  control={controlItem}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={registerItem}
                  required
                  defaultValue={menu?.obj?.BillItemID || ''}
                  helperText={errorsItem?.BillItemID?.message}
                  error={errorsItem?.BillItemID ? true : false}
                />

                {/* Classification e-Invoice */}
                {watch('is_einvoice') === true && (
                  <Controller
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          label="Classification"
                          value={eInvoiceItemName ?? ''}
                          InputProps={{ readOnly: true }}
                          fullWidth
                          disabled
                        />
                      )
                    }}
                    margin="dense"
                    name="einvoice_classification_code_id"
                    ref={registerItem}
                    control={controlItem}
                    disabled
                  />
                )}

                <Controller
                  render={({ value, onChange }) => {
                    const defVal = !!watchItem('CostCentreID')
                      ? getDepartmentListingForForm?.find(
                          x => x?.CostCentreID === watchItem('CostCentreID')
                        )
                      : null
                    return (
                      <Autocomplete
                        options={getDepartmentListingForForm || []}
                        getOptionLabel={option =>
                          option ? `${option?.Code} | ${option?.Name}` : ''
                        }
                        fullWidth
                        onChange={(value, newValue: any) => {
                          onChange(newValue?.CostCentreID)
                        }}
                        disableClearable={
                          !!getDepartmentListingForForm[0]?.IsDefault
                        }
                        renderOption={(props, option) => {
                          return (
                            <div>
                              <div>
                                <span className="xsTitle">{props?.Code}</span>
                              </div>
                              <div className="desc">{props?.Name}</div>
                            </div>
                          )
                        }}
                        value={
                          !!getDepartmentListingForForm[0]?.IsDefault
                            ? getDepartmentListingForForm[0]
                            : defVal
                        }
                        renderInput={(params: any) => {
                          return (
                            <div>
                              <TextField
                                {...params}
                                helperText={
                                  errorsItem?.CostCentreID
                                    ? 'Department is required'
                                    : ''
                                }
                                error={errorsItem?.CostCentreID ? true : false}
                                label="Department"
                                style={{ width: '100%' }}
                                margin="dense"
                                disabled={
                                  !!getDepartmentListingForForm[0]?.IsDefault
                                }
                                required
                              />
                            </div>
                          )
                        }}
                      />
                    )
                  }}
                  label="Department"
                  name="CostCentreID"
                  autoComplete="off"
                  control={controlItem}
                  multiline={true}
                  fullWidth
                  margin="dense"
                  ref={registerItem}
                  helperText={errorsItem?.CostCentreID?.message}
                  error={errorsItem?.CostCentreID ? true : false}
                  disabled={
                    !!getDepartmentListingForForm?.find(
                      x => x?.CostCentreID === watchItem('CostCentreID')
                    )?.IsDefault
                  }
                  required
                />
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  id="standard-basic"
                  name="Amount"
                  label="Amount"
                  value={cost}
                  autoComplete="off"
                  control={controlItem}
                  onValueChange={e => {
                    handleAmtChange(e)
                  }}
                  decimalScale={2}
                  fixedDecimalScale
                  margin="dense"
                  required
                  fullWidth
                  helperText={errorsItem?.Amount?.message}
                  error={errorsItem?.Amount ? true : false}
                  ref={registerItem}
                  defaultValue={menu?.obj?.Amount || ''}
                />

                <Grid
                  item
                  xs={6}
                  style={{ marginBottom: '5px', paddingRight: '10px' }}
                >
                  <TextField
                    name="TaxSchemeID"
                    label="Tax Code *"
                    value={selectedTax ? `${selectedTax?.Code}` : ''}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    defaultValue={menu?.obj?.TaxSchemeID || ''}
                    className="left"
                    disabled
                    margin="dense"
                  />
                </Grid>

                <Grid item xs={6} style={{ marginBottom: '5px' }}>
                  <TextField
                    name="TaxRate"
                    label="Tax Rate %"
                    value={amtStr(taxCodeRate)}
                    margin="normal"
                    style={{ marginTop: '5px' }}
                    disabled
                  />
                </Grid>

                <Controller
                  as={TextField}
                  select
                  value={
                    selectedTax
                      ? `${selectedTax?.Code} ${amtStr(
                          selectedTax?.LatestTax?.Rate ??
                            selectedTax?.TaxEffective?.reduce((a, b) => {
                              return new Date(a?.Date) > new Date(b?.Date)
                                ? a
                                : b
                            })?.Rate ??
                            ''
                        )}`
                      : ''
                  }
                  name="TaxSchemeID"
                  autoComplete="off"
                  control={controlItem}
                  defaultValue={menu?.obj?.TaxSchemeID || ''}
                  ref={registerItem}
                  hidden={true}
                  style={{ display: 'none' }}
                />

                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  thousandSeparator
                  customInput={TextField}
                  ref={registerItem}
                  control={controlItem}
                  margin="dense"
                  name="TaxAmt"
                  label="Tax Amount"
                  onValueChange={e => {
                    handleTaxAmtChange(e)
                  }}
                  value={taxAmt}
                  defaultValue={menu?.obj?.TaxAmt ?? taxAmt ?? 0}
                  decimalScale={2}
                  fixedDecimalScale
                  className="left"
                  InputProps={{ disableUnderline: true }}
                />
                <Controller
                  render={({ value, onChange }) => {
                    return (
                      <TextField
                        label="Doc Amount"
                        value={amtStr(getTotalAmt(true, cost, taxAmt, taxRate))}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        className="right"
                        disabled
                      />
                    )
                  }}
                  disabled
                  margin="dense"
                  defaultValue={menu?.obj?.DocAmt || 0}
                  name="DocAmt"
                  ref={registerItem}
                  control={controlItem}
                />

                <ItemVoiceTextField
                  mounted={true}
                  label="Remark"
                  name="Remark"
                  value={itemVoiceRemark}
                  setValue={setValueItem}
                  record={itemRecord}
                  setRecord={setItemRecord}
                  control={controlItem}
                  controllerProps={{
                    error: errorsItem?.Remark ? true : false,
                    defaultValue: menu?.obj?.Remark,
                    helperText: errorsItem?.Remark?.message,
                    ref: registerItem,
                    autoComplete: 'off',
                  }}
                  required
                />
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenItemDialog(false)
                    setTaxAmt(null)
                    setSelectedTax(null)
                    setClassificationCode(null)
                    setEInvoiceItemName(null)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },

              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleSubmitItem(() =>
                      menu?.obj
                        ? onUpdateDebitNoteItem(watchItem(), menu?.index)
                        : onCreateDebitNoteItem(watchItem())
                    )()
                    setClassificationCode(null)
                    setEInvoiceItemName(null)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
