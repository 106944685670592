import theme from '@ifca-root/react-component/src/assets/theme'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { CardExpansionHeader } from 'components/CardExpansion/CardExpansionHeader'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  GetAuditAdjustmentbyStatusDocument,
  usePostAuditAdjustmentMutation,
  useRejectAuditAdjustmentMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useParams } from 'react-router'
import { AuditAdjustmentDetailContent } from '../AuditAdjustmentDetail/AuditAdjustmentDetailContent'
import { AuditAdjustmentDetailItemContent } from '../AuditAdjustmentDetail/AuditAdjustmentDetailItemContent'

export const AuditAdjustmentPostingList = (props: any) => {
  useEffect(() => {
    console.log('AuditAdjustmentPostingList')
  }, [])
  const {
    filteredList,
    listLoading,
    listStatus,
    postIDs,
    setPostIDs,
    userList,
    setOriginalListing,
    fetchMore,
  } = props

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [openDialog, setOpenDialog] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [clickReject, setIsReject] = useState(false)
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  })
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    postAuditAdjustment,
    {
      loading: postAuditAdjustmentLoading,
      called: postAuditAdjustmentCalled,
      error: postAuditAdjustmentError,
    },
  ] = usePostAuditAdjustmentMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr: [listStatus],
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name === '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  const [
    rejectAuditAdjustment,
    {
      loading: rejectJournalProcessingLoading,
      called: rejectJournalProcessingCalled,
      error: rejectJournalProcessingError,
    },
  ] = useRejectAuditAdjustmentMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      setOriginalListing([])
      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr: [listStatus],
          take: 30,
          skip: 0,
          searchValue:
            getSearch?.name === '' || getSearch?.name === undefined
              ? undefined
              : getSearch?.name,
        },
      })
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  // handle checkbox for all employee
  const handleSelectAll = e => {
    let jTemp = postIDs
    if (e.target.checked) {
      filteredList.map(v => {
        jTemp.add(v?.AuditAdjustmentID)
      })
    } else {
      jTemp.clear()
    }
    setPostIDs(new Set(jTemp))
  }

  // handle checkbox for specific entityCoa
  const handleCheckBoxChange = (e: any, postID, index: number) => {
    let temp = postIDs
    if (!temp.has(postID)) {
      temp.add(postID)
    } else {
      temp.delete(postID)
    }
    setPostIDs(new Set(temp))
  }

  let listPostIDs: any = Array.from(postIDs || [])

  let checkedData = []
  listPostIDs.map(ID => {
    filteredList.map(el => {
      if (el?.AuditAdjustmentID === ID) {
        checkedData.push(...el?.AuditAdjustmentItem)
      }
    })
  })
  const docAmtReducer = (total, curVal) => total + curVal?.DocAmt

  const positiveAmt = checkedData
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = checkedData
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  /* -------------------------------------------- */
  /*                    SUBMIT                   */
  /* -------------------------------------------- */
  const onSubmitApprove = () => {
    postAuditAdjustment({
      variables: {
        AuditAdjustmentIDs: listPostIDs,
        CompanyID,
      },
      refetchQueries: [
        {
          query: GetAuditAdjustmentbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const onSubmitReject = () => {
    rejectAuditAdjustment({
      variables: {
        AuditAdjustmentIDs: listPostIDs,
        input: rejectDialogInput,
      },
      refetchQueries: [
        {
          query: GetAuditAdjustmentbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }
  useEffect(() => {
    if (clickReject) {
      onSubmitReject()
    }
  }, [clickReject])

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  return (
    <>
      {listLoading && <Loading />}
      {postAuditAdjustmentLoading && <Loading />}
      {rejectJournalProcessingLoading && <Loading />}
      <ContentWrapper style={{ marginTop: isDesktop ? '70px' : '-7px' }}>
        {listStatus === 'SUBMIT' && filteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={handleSelectAll}
              color="primary"
              value={''}
              checked={postIDs?.size === filteredList?.length}
              style={{ marginLeft: '-5px', marginTop: '-5px' }}
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum:
                  AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
              })}
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}

        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            <InfiniteScroll
              dataLength={filteredList?.length}
              next={() => {
                const currentLength = filteredList?.length
                fetchMore({
                  variables: {
                    CompanyID: CompanyID,
                    StatusArr:
                      listStatus === 'COMPLETED'
                        ? [listStatus, ApprovalStatus.Cancelled]
                        : [listStatus],
                    take: 30,
                    skip: currentLength,
                    searchValue:
                      getSearch?.name === '' || getSearch?.name === undefined
                        ? undefined
                        : getSearch?.name,
                  },
                })
              }}
              hasMore={true}
              className=""
              style={{ padding: '8px 6px' }}
              loader={
                listLoading && (
                  <div style={{ textAlign: 'center' }}>
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                )
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>-</b>
                </p>
              }
            >
              {advancedFilterList(filteredList)
                ?.sort((a, b) => {
                  return a.createdTs.localeCompare(b.createdTs)
                })
                ?.map((el, index) => {
                  return (
                    <CardExpansion
                      summary={
                        <ListItem key={index}>
                          {listStatus === 'SUBMIT' ? (
                            <FormControl
                              component="fieldset"
                              style={{ width: '16px' }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={el.AuditAdjustmentID}
                                    onChange={e => {
                                      handleCheckBoxChange(
                                        e,
                                        el?.AuditAdjustmentID,
                                        index
                                      )
                                    }}
                                    onClick={e => e.stopPropagation()}
                                    checked={postIDs?.has(
                                      el?.AuditAdjustmentID
                                    )}
                                    color="primary"
                                    style={{
                                      marginLeft: '10px',
                                      marginRight: '-5px',
                                    }}
                                    disabled={handlePermDisabled({
                                      companyID: CompanyID,
                                      permEnum:
                                        AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
                                    })}
                                  />
                                }
                                label=""
                                name="check"
                              />
                            </FormControl>
                          ) : null}

                          <ListItemText
                            style={{
                              marginLeft:
                                listStatus === 'SUBMIT' ? '25px' : null,
                            }}
                            primary={
                              <>
                                <span
                                  className={`xsTitle flex-space click-text`}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/general-ledger/${CompanyID}/audit-adjustment/${el?.AuditAdjustmentID}/preview`,
                                      state: {
                                        ...el,
                                        mode: 'detail',
                                      },
                                    })
                                  }}
                                >
                                  {el?.DocNo}
                                </span>
                                <span
                                  className="desc"
                                  style={{
                                    whiteSpace: 'break-spaces',
                                    lineBreak: 'auto',
                                  }}
                                ></span>
                                <span
                                  className="desc"
                                  style={{
                                    color: '#FF0000',
                                  }}
                                >
                                  {formatDate(el?.DocDate)}
                                </span>
                              </>
                            }
                            secondary={
                              <>
                                <span
                                  className="desc"
                                  style={{
                                    whiteSpace: 'break-spaces',
                                    lineBreak: 'auto',
                                  }}
                                >
                                  <GreyTooltip
                                    onClick={e => e.stopPropagation()}
                                    disableFocusListener
                                    title={
                                      <React.Fragment>
                                        <Grid
                                          container
                                          wrap="nowrap"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Avatar
                                              style={{
                                                width: '20px',
                                                height: '20px',
                                              }}
                                            >
                                              <PersonIcon
                                                style={{ fontSize: 'small' }}
                                              />
                                            </Avatar>
                                          </Grid>
                                          <Grid item xs zeroMinWidth>
                                            <div style={{ fontSize: '12px' }}>
                                              {userList?.find(
                                                x => x?.ID === el?.submittedBy
                                              )?.name ??
                                                userList?.find(
                                                  x =>
                                                    x?.ID === el?.submittedBy ??
                                                    el?.modBy
                                                )?.name}
                                            </div>
                                            <div style={{ fontSize: '10px' }}>
                                              {formatDate(el?.submittedTs)}
                                              &nbsp;
                                              {formatTime(el?.submittedTs)}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </React.Fragment>
                                    }
                                    arrow
                                    enterTouchDelay={0}
                                  >
                                    <div>
                                      <IconText
                                        icon={
                                          <img
                                            src={SubmitterLog}
                                            style={{
                                              width: '12px',
                                              marginRight: '3px',
                                            }}
                                          />
                                        }
                                        font="desc"
                                      ></IconText>
                                    </div>
                                  </GreyTooltip>
                                </span>
                                <span
                                  className="desc flex-space text-overflow"
                                  style={{ marginBottom: '-5px' }}
                                >
                                  {el?.AdjYearOrPeriod}
                                </span>
                                <span className="xxTitle">
                                  <span className="c-orange">
                                    <IconText
                                      font="xxTitle"
                                      children={amtStr(
                                        el?.AuditAdjustmentItem?.filter(
                                          item => item?.DocAmt > 0
                                        )?.reduce((x, y) => x + y?.DocAmt, 0)
                                      )}
                                    ></IconText>
                                  </span>
                                </span>
                              </>
                            }
                          />
                        </ListItem>
                      }
                    >
                      {
                        <>
                          <AuditAdjustmentDetailContent
                            listEl={el}
                            documentListing={el?.Attachment}
                            listStatus={'SUBMIT'}
                            userList={userList}
                            mode={'expansion'}
                          />
                          <CardExpansionHeader title={'Adjustment Detail'} />
                          <AuditAdjustmentDetailItemContent
                            listItem={el?.AuditAdjustmentItem}
                          />
                        </>
                      }
                    </CardExpansion>
                  )
                })}
            </InfiniteScroll>
          )}
        </List>
        <div style={{ marginBottom: '100px' }}></div>
      </ContentWrapper>

      <SubmitterDialog
        data={menu2?.obj}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Audit Adjustment'}
      />

      <RejectDialog
        data={filteredList}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        setRejectDialogInput={setRejectDialogInput}
        setIsReject={setIsReject}
      />

      <TotalAmountFooter
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />

      {listStatus === 'SUBMIT' ? (
        <AccountFooter
          options={[
            {
              name: 'Reject',
              onClick: () => {
                setOpenRejectDialog(true)
              },
              color: 'secondary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
                  }),
              },
            },
            {
              name: 'Approve',
              onClick: () => {
                onSubmitApprove()
              },
              color: 'primary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
                  }),
              },
            },
          ]}
        />
      ) : null}
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
